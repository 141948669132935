<template>
  <slot v-if="!loading" />
  <slot
      v-else
      name="loading">
    <div
        class="loader-pane"
        :style="{'--loader-duration': (duration) ? duration : undefined}"
        :class="{
          'loader-pane--instant': instant,
          'loader-pane--interactive': interactive,
          'loader-pane--duration': !!duration,
        }">
      <slot />
    </div>
  </slot>
</template>
<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: () => false,
    },
    instant: {
      type: Boolean,
      default: () => false,
    },
    interactive: {
      type: Boolean,
      default: () => false,
    },
    duration: {
      type: String,
      default: () => null,
    },
  },
};
</script>
<style>

:root {
  --loader-duration: calc(10 * var(--animation-default-duration));
}

.loader-pane {
  --loader-color: var(--color-secondary-complement-background);
  --loader-min-height: 1rem;
  --loader-min-width: inherit;

  display: grid;
  flex: 1;

  min-width: var(--loader-min-width);
  min-height: var(--loader-min-height);

  background-image: linear-gradient(
      to right,
      transparent 0,
      transparent 25%,
      var(--loader-color) 25%,
      var(--loader-color) 75%,
      transparent 75%,
      transparent 100%
  );
  background-size: 400% 100%;

  animation-name: loading-indefinite;
  animation-duration: var(--loader-duration);
  animation-timing-function: ease-in-out;
  animation-delay: 200ms; /* give it some time to load before getting in their face */
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-fill-mode: both;

  pointer-events: none;

  > * {
    opacity: .5;

    pointer-events: none;
  }

  &--instant {
    animation-delay: 0s;
  }

  &--duration {
    background-image: linear-gradient(
        to right,
        transparent 0,
        transparent 50%,
        var(--loader-color) 50%,
        var(--loader-color) 100%
    );
    background-size: 200% 100%;

    animation-timing-function: linear;
    animation-delay: 0s;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: forwards;
  }

  &--interactive {
    pointer-events: inherit;

    > * {
      opacity: .5;

      pointer-events: inherit;
    }
  }

}

@keyframes loading-indefinite {
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: 0 0;
  }
}

@media (prefers-reduced-motion) {
  .loader-pane {
    animation-duration: 10000ms;
  }
}
</style>
